export default class FetchService {

    async get(endpoint, token) {
        return await this.request(endpoint, 'GET', token);
    }

    
    async put(endpoint, token, body) {
        return await this.request(endpoint, 'PUT', token, body);
    }

    async request(endpoint, method, token, data) {
        return await fetch(`${endpoint}`, {
            method: method,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: data? JSON.stringify(data): null
        })
        .then((response) => this.handleResponse(response))
        .finally((returnValue) => {
            return returnValue;
        });
    }
    
    handleResponse(response) {
        if (response.ok) {
            return response.status === 200 ? response.json() : Promise.resolve(null);
        } else {
            return Promise.reject(response.status);
        }
    }
}