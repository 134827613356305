import { configureStore } from '@reduxjs/toolkit';
import reducer from './reducer'

const localStorageMiddleware = () => (next) => (action) => {
    switch (action.type) {
        case 'lead/add': 
            localStorage.setItem('lead', JSON.stringify(action.payload));
            break;
        case 'lead/reset': 
            localStorage.removeItem('lead');
            break;
        default:
            break;
    }
    
    return next(action);
}

const rehydrate = {
    lead: JSON.parse(localStorage.getItem('lead')) || undefined
}

const store = configureStore({
    reducer: reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(localStorageMiddleware),
    preloadedState: rehydrate
})

export default store