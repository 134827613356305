import './App.css';
import Search from './components/Search';
import ViewLead from "./components/ViewLead";
import Logout from "./components/Logout";
import PageLoader from './components/PageLoader';
import { Routes, Route, Navigate } from "react-router-dom";
import { useAuth0 } from '@auth0/auth0-react';
import Unauthorized from './components/auth/Unauthorized';
import Unauthenticated from './components/auth/Unauthenticated';
import config from './config.json'
import RequiresAuth from './components/auth/RequiresAuth';
import NotFound from './components/NotFound';

function App() {

  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <PageLoader />;
  }
  
  return (
    <div className="app">
      <Routes>
        <Route exact path="/" element={<RequiresAuth component={Search} requiredRole={config.viewLeadsRole} />} />
        <Route exact path="/logout" element={ isAuthenticated? <Logout /> : <Navigate to="/unauthorized" /> } />
        <Route exact path="lead/:id" element={<RequiresAuth component={ViewLead} requiredRole={config.viewLeadsRole} />} />
        <Route exact path="/unauthorized" element={ <Unauthorized /> } />
        <Route exact path="/unauthenticated" element={ <Unauthenticated /> } />
        <Route exact path="*" element={ <NotFound /> } />
      </Routes>
    </div>
  );
}

export default App;
