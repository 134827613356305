import { useAuth0 } from "@auth0/auth0-react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const StyledLink = styled(Link)`
  color: black;
  top: 30vh;
  position: absolute;
`;

const Logout = () => {
  const { logout } = useAuth0();

  return (
    <>
        <StyledLink onClick={() => logout({ returnTo: window.location.origin })}>Log Out</StyledLink>
    </>
  );
};

export default Logout;