import styled from "styled-components";
import React, { createRef } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEarListen } from '@fortawesome/free-solid-svg-icons';

//#region styled components
const ScreenerContainer = styled.div`
    display: flex;
    justify-content: space-between;
    @media (max-width: 799px) {
        flex-direction: column;
    }
`;

const ScreenerHeaderLeft = styled.div`
    text-align: left;
    border-bottom: solid 1px var(--medium-gray);
    font-size: 1.9em;
    padding-bottom: 0.5em;
`;

const ScreenerHeaderRight = styled.div`
    text-align: right;
    border-bottom: solid 1px var(--medium-gray);
    font-size: 1.9em;
    padding-bottom: 0.5em;
    @media (max-width: 799px) {
       text-align: left;
    }
`;

const ScreenerResults = styled.div`
    width: 50%;
    box-sizing: border-box;
    padding: 1em 2em 1em 2em;
    :nth-child(1) {
        border-right: solid 1px var(--light-blue);
    }
    
    @media (max-width: 799px) {
        width: 100%;
        :nth-child(1) {
            border-right: none;
        }
    }
`;

const ScreenerGrid = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 33% 33% 33%;
    margin-bottom: 1em;
`;

const ScreenerCell = styled.div`
    border-bottom: solid 1px var(--light-gray);
    line-height: 12px;
    padding: 18px 0;
    &:nth-child(3n),
    &:nth-child(3n+1) {
        font-weight: bold;
    }
`;
const NoResultsWrapper = styled.div`
    font-style: italic;
    text-align:center;
`
//#endregion

const Screener = ({Box, BoxHeader, Chevron, rotateChevron, screenerResults, version}) => {

  const chevron = createRef(null);

  const renderV1Results = () => {
    return screenerResults.map((result, index) => {
        return (
            <ScreenerContainer key={index}>
                <ScreenerResults>
                    <ScreenerHeaderLeft>
                        <FontAwesomeIcon icon={faEarListen} className="reverse-svg right-ear" />
                        Right Ear
                    </ScreenerHeaderLeft>
                    <ScreenerGrid>
                    {result.Right?.experiment?.trialResults?.map((trial, index) => {
                        return trial.audible === "true" ? (<React.Fragment key={index}>
                                    <ScreenerCell>{trial.tone.frequency}hz</ScreenerCell>
                                    <ScreenerCell>AUDIBLE AT</ScreenerCell>
                                    <ScreenerCell>{trial.tone.intensityDbHL} DbHL</ScreenerCell>
                                </React.Fragment>) : null
                        })
                    }
                    </ScreenerGrid>
                    { !(result.Right?.experiment?.trialResults) ? <NoResultsWrapper>No results</NoResultsWrapper> : null}
                </ScreenerResults>
                <ScreenerResults>
                    <ScreenerHeaderRight>
                        Left Ear
                        <FontAwesomeIcon icon={faEarListen} className='left-ear' />
                    </ScreenerHeaderRight>
                    <ScreenerGrid>
                        {result.Left?.experiment?.trialResults?.map((trial, index) => {
                            return trial.audible === "true" ? (<React.Fragment key={index}>
                                <ScreenerCell>{trial.tone.frequency}hz</ScreenerCell>
                                <ScreenerCell>AUDIBLE AT</ScreenerCell>
                                <ScreenerCell>{trial.tone.intensityDbHL} DbHL</ScreenerCell>
                            </React.Fragment>) : null
                    })
                        }
                    </ScreenerGrid>
                    { !(result.Left?.experiment?.trialResults) ? <NoResultsWrapper>No results</NoResultsWrapper> : null}
                </ScreenerResults>
            </ScreenerContainer>
            );
        })
    }

    const renderV2Results = () => {
        let left = screenerResults[0]?.Left !== "null" ? screenerResults[0]?.Left : null;
        let right = screenerResults[0]?.Right !== "null" ? screenerResults[0]?.Right : null;
        let leftHz = left && Object.entries(left?.experiment);
        let rightHz = right && Object.entries(right?.experiment);
            return (
                <ScreenerContainer>
                    <ScreenerResults>
                        <ScreenerHeaderLeft>
                            <FontAwesomeIcon icon={faEarListen} className="reverse-svg right-ear" />
                            Right Ear
                        </ScreenerHeaderLeft>
                        <ScreenerGrid>
                        {
                        rightHz?.map(([freq, at], index) => {
                            return <React.Fragment key={index}>
                                        <ScreenerCell>{freq}hz</ScreenerCell>
                                        <ScreenerCell>AUDIBLE AT</ScreenerCell>
                                        <ScreenerCell>{at} DbHL</ScreenerCell>
                                    </React.Fragment>
                            })
                        }
                        </ScreenerGrid>
                        { !right || !rightHz ? <NoResultsWrapper>No results</NoResultsWrapper> : null}
                    </ScreenerResults>
                    <ScreenerResults>
                        <ScreenerHeaderRight>
                            Left Ear
                            <FontAwesomeIcon icon={faEarListen} className='left-ear' />
                        </ScreenerHeaderRight>
                        <ScreenerGrid>
                        {
                        leftHz?.map(([freq, at], index) => {
                            return <React.Fragment key={index}>
                                        <ScreenerCell>{freq}hz</ScreenerCell>
                                        <ScreenerCell>AUDIBLE AT</ScreenerCell>
                                        <ScreenerCell>{at} DbHL</ScreenerCell>
                                    </React.Fragment>
                            })
                        }
                        </ScreenerGrid>
                        { !left || !leftHz ? <NoResultsWrapper>No results</NoResultsWrapper> : null}
                    </ScreenerResults>
                </ScreenerContainer>
                );
        }

  return(
    <Box>
        <BoxHeader htmlFor="screenerCheckbox">
            SCREENER
            <Chevron className="open-chevron" ref={chevron}></Chevron>
        </BoxHeader>
        <input type="checkbox" id="screenerCheckbox" onChange={e=> rotateChevron(chevron)}></input>
        <div className="contents">
            {version === "2.0" ? renderV2Results() : renderV1Results()}
        </div>
    </Box>
    );
};

export default Screener;