import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import React from "react";
import Redirecting from "../Redirecting";
import config from '../../config.json'
import { Navigate } from "react-router-dom";

const RequiresAuth = ({ component, requiredRole }) => {
  const { isAuthenticated, user } = useAuth0();
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
        <Redirecting />
    ),
  });

  if (isAuthenticated && requiredRole &&  !user[`${config.auth0Namespace}/roles`]?.includes(requiredRole)) {
    return <Navigate replace to='/unauthorized' />;
  }


  return <Component />;
};

export default RequiresAuth;