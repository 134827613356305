import styled from "styled-components";
import { createRef } from "react";

//#region styled components
const QuestionnaireRow = styled.div`
      text-align: left;
      padding: 0 1.5em;
      margin-bottom: 2em;
      :nth-child(1) {
          padding-top: 1.5em;
      }
      :nth-last-child(1) {
          margin-bottom: 2em;
      }
  `;

const QuestionWrapper = styled.div`
    line-height: 1em;
    margin: 0 0 .5em 0;
`;

const Answer = styled.div`
    color: var(--blue);
    line-height: 1.5em;
`;

const AnswerTable = styled.table`
    border-collapse: collapse;
    border-bottom: solid 1px var(--medium-gray);
    width: 100%;
    margin-bottom: 1em;
    > tbody tr td:nth-child(1) {
        background-color: var(--medium-gray);
        color: var(--darkest-gray);
        padding: 0;
        width: 1em;
        text-align: center;
        font-weight: bold;
        font-size: 1.5em;
        vertical-align: baseline;
        padding-top: .2em;
        font-family: 'Times New Roman';
      }
    }
    > tbody tr td:nth-child(2) {
        vertical-align: top;
        padding: .5em;
    }
`;
//#endregion

const Questionnaire = ({Box, BoxHeader, Chevron, rotateChevron, questionnaireResults}) => {

  const chevron = createRef(null);
  const displayAnswer = (answer) => {
    if (!answer.map) {
        answer = [answer];
    }
    return answer.map((val, index) => {
        return <Answer key={index}>{val}</Answer>
    })
  }
  return (
    <Box>
        <BoxHeader htmlFor='questionnaireCheckbox'>
            QUESTIONNAIRE
            <Chevron className="open-chevron" ref={chevron}></Chevron>
        </BoxHeader>
        <input type="checkbox" id="questionnaireCheckbox" onChange={e => rotateChevron(chevron)}></input>
        <div className="contents">
        {questionnaireResults.map((result) => {
            return (
                <QuestionnaireRow key={result.question}>
                    <QuestionWrapper key={result.question}>{result.question}</QuestionWrapper>
                        <AnswerTable>
                        <tbody>
                        <tr>
                            <td>“</td>
                            <td>{displayAnswer(result.answer)}</td>
                        </tr>
                        </tbody>
                        </AnswerTable>
                </QuestionnaireRow>
                );
            })
        }
        </div>
    </Box>
    );
};

export default Questionnaire;