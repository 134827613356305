import config from '../config.json'
import FetchService from './fetchService';

export default class LeadService {

    constructor() {
        this.fetchService = new FetchService();
        this.config = config;
        this.getLeadEndpoint = config.apiBaseUrl + config.getLeadEndpoint;
    }

    async getLead(id, token) {
        return await this.fetchService.get(this.getLeadEndpoint + id, token);
    }

    async markLeadAsViewed(id, token) {
        return await this.fetchService.put(`${this.getLeadEndpoint + id}/viewed`, token);
    }
}