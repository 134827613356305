import '../Search.css';
import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import LandingHeader from './LandingHeader';
import UserHeader from './UserHeader';
import { useAuth0 } from '@auth0/auth0-react';

//#region styled components

const StyledForm = styled.form`
    min-height: 100px;
    min-width: 320px;
    position: relative;
    width: 35vw;
    margin: 0 auto;
    display: flex;
    align-items: center;
`;

const StyledInput = styled.input`
    width: 100%;
    border: 1px solid var(--medium-dark-gray);
    border-radius: 17px;
    padding: .5em 1.25em; 
    box-sizing: border-box;
    height: 34px;
    &:focus {
        outline: none;
    }
    &:placeholder-shown {
        font-style: italic;
    }
`;

const StyledSubmitButton = styled.button`
    height: 34px;
    width: 34px;
    border: none;
    border-radius: 50%;
    padding: 0.25em 0.35em;
    background-color: var(--green-yellow);
    position: absolute;
    right: 0;
    cursor: pointer;
    font-size: .8em;
    &:focus {
        outline: none;
    }
    &:hover {
        background-color: var(--green-yellow-darker);
        color: var(--almost-black);
    }
`;
const Intro = styled.div`
    // max-width: 500px;
    margin: 20px 0;
    width: 35vw;
`

const EmailLink = styled.a`
  color: var(--purple);
  cursor: pointer;
  font-weight: bold;
  text-decoration: underline;
  &:hover {
    color: var(--purple-hover);
  }
`
//#endregion

const Search = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [id, setId] = useState('');
    const [isDisabled, setDisabled] = useState(false);
    const messageRef = useRef(null);
    const { getAccessTokenSilently } = useAuth0();

    useEffect(()=> {
        // reset state
        dispatch({ type: 'lead/reset' });
        // disable submit button
        setDisabled(true);
    }, [dispatch, getAccessTokenSilently]);
    const onChange = (e) => {
        if(e.target.value) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
        setId(e.target.value);
    }

    const handleSubmit = async (evt) => {
        evt.preventDefault();

        setDisabled(true);
        navigate(`/lead/${id}`);
    };

    return (
        <>
            <UserHeader />
            <LandingHeader />
            <Intro>Welcome to the Hearingloss.com lead portal. To view the details of a lead, enter the ID below and press the search button:</Intro>
            <StyledForm onSubmit={handleSubmit}>
                <StyledInput type="text" name="LeadID" placeholder="Enter lead ID" value={id} onChange={onChange}  />
                <StyledSubmitButton disabled={isDisabled}>
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                </StyledSubmitButton>
                <div ref={messageRef}></div>
            </StyledForm>
            <Intro>If you would like to modify the list of emails at your practice that receive patient lead email notifications, please send an email to <EmailLink href="mailto:support@hearingloss.com">support@hearingloss.com</EmailLink> with those details and we will update your distribution list.</Intro>
        </>
    );
};

export default Search;