const initialState = {
    lead: {}
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case 'lead/add':
            return {
                ...state,
                lead: action.payload
            };
        case 'lead/reset': 
            return initialState;
        default:
            return state;
    }
}