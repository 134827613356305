import styled from "styled-components";

const LogoImg = styled.img`
    height: 75px;
    pointer-events: none;
`;

const Header = styled.div`
    margin-top 15vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
`;

const LandingHeader = () => {  
    return (
      <> 
        <Header>
            <LogoImg src="hl_logo_horiz.png" alt="logo" />
        </Header>
      </>
    );
  };
  
export default LandingHeader;