import '../View.css';
import React, { createRef, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useScreenshot, createFileName } from 'use-react-screenshot'
import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faDownload, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import ConversionService from '../services/conversionService';
import Questionnaire from './Questionnaire';
import Screener from './Screener';
import UserHeader from './UserHeader';
import LeadService from '../services/leadService';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux'
import momentTimezone from "moment-timezone/builds/moment-timezone-with-data";

//#region styled components

const LogoImg = styled.img`
    height: 38px;
    margin: 20px 0;
    cursor: pointer;
`;

const DownloadButton = styled.button`
    background-color: var(--green-yellow);
    border-radius: 2em;
    border: none;
    padding: .8em 1.5em;
    cursor: pointer;
    .fa-download {
        margin-right: .8em
    }
`;

const ProspectHeader = styled.div`
    display: grid;
    grid-template-columns: 140px 1fr 140px;
    font-size: 1em;
    align-items: center;
    justify-content: space-between;
    background-color: var(--purple);
    color: white;
    padding: 1em 1.5em;
    border-radius: .25em .25em 0 0;
`;

const Contact = styled.div`
    display: flex;
    font-size: 0.875em;
    justify-content: space-between;
    background-color: white;
    padding: 1em;
    line-height: 1.5em;
    a {
        color: var(--black);
        text-decoration: none;
    }
`;

const ContactH1 = styled.h1`
    font-weight: normal;
    font-size: 1.8em;
    margin: 0 0 .25em 0;
`;

const ContactRight = styled.div`
    text-align: right;
    .fa-envelope, .fa-phone {
        font-style: normal;
        margin-left: 0.8em;
        color: var(--dark-gray);
    }
`;

const ProspectBody = styled.div`
    background-color: white;
    padding: 0 1em 1em 1em;
    margin-bottom: 1em;
    border-radius: 0 0 .25em .25em;
`;

const FormRow = styled.div`
    display: flex;
    line-height: 3em;
    font-size: .8em;
    > :nth-child(1) {
        color: var(--darkest-gray);
      }
    }
    @media (min-width: 300px) {
    > :nth-child(1) {
        width: 20%;
      }
    }
    @media (min-width: 300px) {
    > :nth-child(2) {
        width: 80%;
    }
}`;

const BoxHeader = styled.label`
    background-color: var(--light-blue);
    color: white;
    padding: 0 1em;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    + input[type=checkbox] {
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        height: 40px;
        display: none;
    }
`;

const Chevron = styled.div`
    margin: 1em 0 0 0;
    border-style: solid;
    border-width: 0.2em 0.2em 0 0;
    content: '';
    display: inline-block;
    height: 0.45em;
    left: 0.15em;
    position: relative;
    top: 0.15em;
    vertical-align: top;
    width: 0.45em;
`;

const Box = styled.div`
    border: solid 1px var(--light-blue);
    margin: 1em 0;
    text-align: center;
    overflow: hidden;
    border-radius: 3px;
`;

const HR = styled.hr`
    border-top: 1px solid var(--light-gray);
    border-width: 1px 0 0 0;
    margin: 0;
`;

const ErrorMessage = styled.div`
    text-align: center;
    padding: 50px 100px;
    background-color: white;
`

const MinorInfo = styled.div`
    font-style: italic;
`

const EmailLink = styled.a`
  color: var(--purple);
  cursor: pointer;
  font-weight: bold;
  text-decoration: underline;
  &:hover {
    color: var(--purple-hover);
  }
`

const GoBackButton = styled.div`
  padding: 10px 80px;
  background-color: var(--purple);
  color: white;
  text-align: center;
  cursor: pointer;
  margin: 20px;
  display: inline-block;
  border-radius: 3px;
  &:hover {
    background-color: var(--purple-hover)
  }
`

const BackLink = styled.a`
    color: white;
    cursor: pointer;
    font-weight: bold;
    display: inline-block;
    &:hover {
        color: white;
    }
`

//#endregion

const ViewLead = () => {
    let { id } = useParams();
    const { getAccessTokenSilently, user } = useAuth0();
    const conversionService = new ConversionService();            
    const pageRef = createRef(null);
    const [lead, setLead] = useState(null);
    const [hasLoaded, setHasLoaded] = useState(false);
    const [noLeadMessage, setNoLeadMessage] = useState("");

    // eslint-disable-next-line no-unused-vars
    const [image, takeScreenShot] = useScreenshot({
        type: "image/jpeg",
        quality: 1.0
    });

    // let lead = useSelector(state => state.lead);
    const navigate = useNavigate();

    let name = '';
    let age = '';
    if (lead && lead.Details) {
        name = conversionService.getLeadName(lead);
        age = conversionService.getLeadAge(lead);
        // ({ name, age } = conversionService.convertLeadToParts(lead));
    }



    useEffect(() => {
        const loadLead = async () => {
            // console.log ('loading lead with user email: ' + user?.email)
            const token = await getAccessTokenSilently();
            const conversionService = new ConversionService();            
            const leadService = new LeadService();           
            leadService
                .getLead(id, token)
                .then((response) => {
                    response = conversionService.convertAnswers(response);
                    response = conversionService.convertTime(response);
                    setNoLeadMessage(null);
                    setLead(response.response)
                    setHasLoaded(true);
                }).catch((status) => {
                    setLead(null);
                    setHasLoaded(true);
                    if(status === 404) {
                        setNoLeadMessage(`Lead could not be found by id: ${id}`);
                    } else {
                        setNoLeadMessage(`An error occured loading lead id: ${id}`)
                    }
                });
        }
        
        loadLead();
        
    }, [id, user, getAccessTokenSilently, setLead])

    useEffect(() => {
        const markLeadAsViewed = async () => {
            let userIsStaff = user?.email?.split('@')[1].match(/^axian\.com|audigy\.com$/g);
            // If the lead is loaded, the user is NOT audigy staff, and it isn't already marked as viewed
            if(hasLoaded && !userIsStaff && !lead?.Viewed) {
                const token = await getAccessTokenSilently();
                const leadService = new LeadService();           
                leadService.markLeadAsViewed(id, token)
                    .then((response) => {
                        lead.Viewed = true;
                        lead.DateFirstViewed = momentTimezone().format();
                        setLead(lead);
                        // console.log('Can update lead as viewed: ' + lead.LeadId)
                    })
                    .catch((status) => {
                        console.error('Failed to update lead as viewed by id: ' + id)
                    })
            } 
        }
        markLeadAsViewed();
    }, [hasLoaded, id, user, getAccessTokenSilently, lead])

    const download = (image, { name = 'img', extension = 'jpg' } = {}) => {
        const a = document.createElement('a');
        a.href = image;
        a.download = createFileName(extension, name);
        a.click();
    };

    const downloadScreenshot = () => takeScreenShot(pageRef.current).then(download);

    const rotateChevron = (target) => {
        target.current.classList.toggle('open-chevron');
        target.current.classList.toggle('closed-chevron');
    }

    const renderGhx = () => {
        let questions = lead.Details?.questionnaireResults?.result;
        let screener = lead.Details?.screenerResults?.result;

        if(!questions?.length && !screener?.length) {
            return <MinorInfo>Did not attempt</MinorInfo>
        }

        return <>
            { questions?.length > 0 &&
                <Questionnaire questionnaireResults={questions} Box={Box} BoxHeader={BoxHeader} Chevron={Chevron} rotateChevron={rotateChevron} />
            }
            {screener?.length > 0 &&
                <Screener screenerResults={screener} version={lead.Details?.screenerResults?.version} Box={Box} BoxHeader={BoxHeader} Chevron={Chevron} rotateChevron={rotateChevron} />
            }
        </>
    }

    const renderNoLead = (hasLoaded, errorMessage) => {
        if (!hasLoaded) {
            return <ErrorMessage>Loading</ErrorMessage>
        } else {
            return <ErrorMessage>
                <p>{noLeadMessage}</p>
                <p>
                If you feel you've received this message in error, try clicking the link you received in your original email or ensure you've copied and pasted the correct Lead ID. For further assistance, please contact <EmailLink href="mailto:support@hearingloss.com">support@hearingloss.com</EmailLink>
                </p>
                <GoBackButton onClick={ ()=> navigate("/") }>Back to Search</GoBackButton>
            </ErrorMessage>
        }
    }

    return (
        <> {lead ?
            <div className="main-content" ref={pageRef}>
                <UserHeader />
                <LogoImg src="../hl_logo_horiz.png" alt="logo" onClick={()=> navigate("/")} />
                <ProspectHeader>
                    <BackLink onClick={ ()=> navigate("/") }><FontAwesomeIcon icon={faCaretLeft} /> Back to search </BackLink>  | View Lead
                    <DownloadButton onClick={downloadScreenshot}>
                        <FontAwesomeIcon className="download-icon" icon={faDownload} />
                        Download
                    </DownloadButton>
                </ProspectHeader>
                <Contact className="contact">
                    <div>
                        {name &&
                            <ContactH1>{name}</ContactH1>
                        }
                        {lead.Details.postalCode &&
                            <div>Zip: {lead.Details.postalCode}</div>
                        }
                        {age &&
                            <div>Age: {age}</div>
                        }
                    </div>
                    <ContactRight>
                        {lead.Details.email &&
                        <div>
                            <a href={`mailto: ${lead.Details.email}`}>
                                {lead.Details.email}
                                <FontAwesomeIcon icon={faEnvelope} />
                            </a>
                        </div>
                        }
                        {lead.Details.phone &&
                        <div>
                            <a href={`tel: ${lead.Details.phone}`}>
                                {lead.Details.phone}
                                <FontAwesomeIcon icon={faPhone} className="" />
                            </a>
                        </div>
                        }
                    </ContactRight>
                </Contact>
                <ProspectBody>
                    <HR />
                    <FormRow>
                        <div>Id:</div>
                        <div>{lead.LeadId}</div>
                    </FormRow>
                    {lead.Received &&
                    <FormRow>
                        <div>Received:</div>
                        <div>{lead.Received}</div>
                    </FormRow>
                    }
                    {lead.Details?.leadType &&
                    <FormRow>
                        <div>Reason:</div>
                        <div>{lead.Details.leadType}</div>
                    </FormRow>
                    }
                    {lead.Details?.locationInfo?.name &&
                    <FormRow>
                        <div>For Location:</div>
                        <div>{lead.Details.locationInfo.name}</div>
                    </FormRow>
                    }
                    {lead.Details?.message &&
                    <FormRow>
                        <div>Message:</div>
                        <div className="message">
                            {lead.Details.message}
                        </div>
                    </FormRow>
                    }
                    <FormRow>
                        <div>GHX Results:</div>
                        <div>
                            {renderGhx()}
                        </div>
                    </FormRow>
                    {lead.Viewed && 
                    <FormRow>
                        <div>Viewed:</div>
                        <div>
                            {conversionService.getFormattedTime(lead.DateFirstViewed)}
                        </div>
                    </FormRow>
                    }
                </ProspectBody>
            </div> : 
            <div className="main-content">
                <UserHeader />
                <LogoImg src="../hl_logo_horiz.png" alt="logo" onClick={()=> navigate("/")} />
                {renderNoLead(hasLoaded)}
            </div>
            }
        </>
    );
};

export default ViewLead;