import momentTimezone from "moment-timezone/builds/moment-timezone-with-data";
export default class ConversionService {
  // format all answers to be objects (some are strings)
  convertAnswers(response) {
    if (response?.response?.Details?.questionaireResults?.result?.length > 0) {
      response.response.Details.questionaireResults.result.map((result) => {
        if (typeof(result.answer) === 'string') {
          result.answer = [result.answer];
        }
        return result;
      })
    }
    return response;
  }

  convertTime(response, timezone) {
    if (response.response.Received) {
      var zone = momentTimezone.tz.guess();
      var tzDate = momentTimezone.tz(response.response.Received, zone);
      // timezone format tokens have been deprecated from plain Moment objects https://github.com/moment/moment/issues/162
      response.response.Received = tzDate.format('MMMM Do, YYYY hh:mmA z');
    }
    return response;
  }

  getFormattedTime(timeString) {
    if(timeString) {
      var zone = momentTimezone.tz.guess();
      var tzDate = momentTimezone.tz(timeString, zone);
      // timezone format tokens have been deprecated from plain Moment objects https://github.com/moment/moment/issues/162
      return tzDate.format('MMMM Do, YYYY hh:mmA z');
    }
    return timeString;
  }

  getLeadAge(lead) {
    let age = '';
    let screenResults = lead.Details?.screenerResults?.result;
    // V1 results age
    if (screenResults && screenResults.length > 0) {
      age = screenResults[0].Left?.subject?.age 
            ?? screenResults[0].Left?.experiment?.experimentSubject?.age
            ?? screenResults[0].Right?.subject?.age
            ?? screenResults[0].Right?.experiment?.experimentSubject?.age;
             
    }
    // V2 results age
    if (lead.Details.birthYear) {
      // birth year could be "1950" or "18-25"
      age = lead.Details.birthYear.match(/^\d+$/)
        ? parseInt(momentTimezone().format("YYYY")) - parseInt(lead.Details.birthYear)
        : lead.Details.birthYear;
    }
    return age;
  }

  getLeadName(lead) {
    let name = '';
    if (lead.Details?.lastName && lead.Details?.firstName) {
      name = `${lead.Details?.lastName}, ${lead.Details.firstName}`;
    } else {
      name = lead.Details?.lastName ?? lead.Details?.firstName
    }
    return name;
  }
  
  convertLeadToParts(lead) {
    let name = '';
    if (lead.Details?.lastName && lead.Details?.firstName) {
      name = `${lead.Details?.lastName}, ${lead.Details.firstName}`;
    } else {
      name = lead.Details?.lastName ?? lead.Details?.firstName
    }

    let age = '';
    let screenResults = lead.Details?.screenerResults?.result;
    if (screenResults && screenResults.length > 0) {
      age = screenResults[0].Left?.subject?.age 
            ?? screenResults[0].Left?.experiment?.experimentSubject?.age
            ?? screenResults[0].Right?.subject?.age
            ?? screenResults[0].Right?.experiment?.experimentSubject?.age;
             
    }
    if (lead.Details.birthYear && parseInt(lead.Details.birthYear)) {
      age = parseInt(momentTimezone().format("YYYY")) - parseInt(lead.Details.birthYear)
    }
    return { name, age };
  }
}