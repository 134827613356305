import styled from "styled-components";
const Container = styled.div`
  background-color: var(--light-gray);
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const LoadingMessage = styled.div`
  top: 30vh;
  position: absolute;
  text-align:center;
`;

const PageLoader = () => {
  return (
    <Container>
      <LoadingMessage>Loading ...</LoadingMessage>
    </Container>
  )
}
export default PageLoader;