import styled from "styled-components";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";

const Wrapper = styled.div`
    position: absolute;
    top: 10px;
    right: 20px;
    display:grid;
    color: var(--almost-black);
    grid-template-columns: 1fr 62px;
    text-align:right;
    gap: 20px;
    max-width: 100vw;
`

const StyledLink = styled(Link)`
    color: var(--almost-black);
`;

const UserHeader = () => {  
    const { logout, user, isAuthenticated, loginWithRedirect } = useAuth0();

    const loggedIn = <Wrapper>
      <span>Welcome, {user?.email}</span>
      <StyledLink onClick={() => logout({ returnTo: window.location.origin })}>Log Out</StyledLink>
    </Wrapper>;
    
    const loggedOut = <Wrapper><StyledLink onClick={() => loginWithRedirect()}>Log In</StyledLink></Wrapper>;

    return isAuthenticated ? loggedIn : loggedOut;
  };
  
export default UserHeader;