import styled from "styled-components";
import LandingHeader from "../LandingHeader";
import UserHeader from '../UserHeader';

//#region styled components

const Wrapper = styled.div`
  min-width: 320px;
  position: relative;
  width: 34vw;
  margin: 0 auto;
  text-align: center;
`;

const Intro = styled.div`
text-align: left;
margin-bottom: 20px;
`
const Instructions = styled.ul`
  text-align: left;
`
const EmailLink = styled.a`
  color: var(--purple);
  cursor: pointer;
  font-weight: bold;
  text-decoration: underline;
  &:hover {
    color: var(--purple-hover);
  }
`
//#endregion

const Unauthorized = () => {

    return (
      <>
        <UserHeader />
        <LandingHeader />
        <Wrapper>
          <Intro>We're sorry, but you don't have access to this page.</Intro>
          <Intro>If you just signed up, it's possible your access auto-assigned. <EmailLink href="/">Click here to go back</EmailLink> to the home page and try again.</Intro>
          <Intro>If you believe you should have access please send an email to <EmailLink href="mailto:support@hearingloss.com">support@hearingloss.com</EmailLink> with the following details:</Intro>
          <Instructions>
            <li>The practice and/or office you are associated with</li>
            <li>Your first & last name</li>
            <li>The email address of the account you just created</li>
            <li>Your phone number</li>
          </Instructions>
          <Intro>You will be contacted within 1-2 business days to approve your access.</Intro>
        </Wrapper>
      </>
    );
  };
  
  export default Unauthorized;