import styled from "styled-components";
import LandingHeader from "./LandingHeader";

//#region styled components

const Wrapper = styled.div`
  min-width: 320px;
  position: relative;
  width: 30vw;
  margin: 0 auto;
  text-align: center;
`;

const Intro = styled.div`
    margin-bottom: 20px;
`
//#endregion

const Redirecting = () => {
    return (
        <>
        {/* <UserHeader /> */}
        <LandingHeader />
        <Wrapper>
          <Intro>Redirecting to login...</Intro>
        </Wrapper>
      </>
      );
}

export default Redirecting