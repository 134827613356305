import { Link } from "react-router-dom";
import styled from "styled-components";
import LandingHeader from "./LandingHeader";
import UserHeader from "./UserHeader";

//#region styled components

const Wrapper = styled.div`
  min-width: 320px;
  position: relative;
  width: 30vw;
  margin: 0 auto;
  text-align: center;
`;

const Intro = styled.div`
    margin-bottom: 20px;
`
//#endregion

const NotFound = () => {
    return (
        <>
        <UserHeader />
        <LandingHeader />
        <Wrapper>
          <Intro>The page you have requested does not exist.</Intro>
          <Link to={'/'} >Return to the home page</Link>
        </Wrapper>
      </>
      );
}

export default NotFound