import { useAuth0 } from "@auth0/auth0-react";
import styled from "styled-components";
import LandingHeader from "../LandingHeader";
//#region styled components
const StyledForm = styled.div`
  max-width: 400px;
  min-width: 200px;
  position: relative;
  margin: 0 auto;
  text-align: center;
  margin-top:20px;
`;

const LoginButton = styled.div`
  padding: 10px 80px;
  background-color: var(--purple);
  color: white;
  text-align: center;
  cursor: pointer;
  margin: 20px;
  display: inline-block;
  border-radius: 3px;
  &:hover {
    background-color: var(--purple-hover)
  }
`
const SignUpButton = styled.div`
  cursor: pointer;
  text-decoration: underline;
  text-align: center;
`

const Description = styled.div `
padding: 0 60px;
`
//#endregion

const Unauthenticated = () => {
  const { loginWithRedirect  } = useAuth0();
    return (
      <>
        <LandingHeader />
        <StyledForm>
          <Description>To access the HearingLoss.com Leads Portal you need to have an account</Description>
          <LoginButton onClick={ loginWithRedirect }>Login</LoginButton>
          <SignUpButton  onClick={ () => loginWithRedirect({ screen_hint: 'signup', }) }>Create Account</SignUpButton>

        </StyledForm>
      </>
    );
  };
  
  export default Unauthenticated;